const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  CLEAR_AUTHORIZATION: 'CLEAR_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGOUT_ERROR: 'LOGOUT_ERROR',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  CREATE_PASSWORD: 'CREATE_PASSWORD',
  CREATE_PASSWORD_SUCCESS: 'CREATE_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  CHANGE_PROFILE: 'CHANGE_PROFILE',
  CHANGE_PROFILE_SUCCESS: 'CHANGE_PROFILE_SUCCESS',
  clearAuthorization: () => ({ type: actions.CLEAR_AUTHORIZATION }),
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (payload) => ({
    type: actions.LOGIN_REQUEST,
    payload,
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
  logoutSuccess: () => ({
    type: actions.LOGOUT_SUCCESS
  }),
  forgotPassword: (email) => ({
    type: actions.FORGOT_PASSWORD,
    payload: {email}
  }),
  changePassword: (password, newPassword) => ({
    type: actions.CHANGE_PASSWORD,
    payload: {password, newPassword}
  }),
  forgotPasswordSuccess: (message) => ({
    type: actions.FORGOT_PASSWORD_SUCCESS,
    payload: {message}
  }),
  resetPassword: ({token, password}) => ({
    type: actions.RESET_PASSWORD,
    payload: {token, password}
  }),
  createPassword: ({token, password}) => ({
    type: actions.CREATE_PASSWORD,
    payload: {token, password}
  }),
  changeProfile: (firstName, lastName) => ({
    type: actions.CHANGE_PROFILE,
    payload: {firstName, lastName}
  })
};
export default actions;
