export const PUBLIC_ROUTE = {
  LANDING: '/',
  SIGN_IN: '/signin',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/restore-password',
  CREATE_PASSWORD: '/create-password',
  PAGE_404: '/404',
  PAGE_500: '/500',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  PRIVACY_POLICY: '/privacy-policy',
};
