import NetworkBase from '../base';

class Index extends NetworkBase {
    constructor() {
        super('admins');
    }

    /**
     * Change password
     * @param model
     * @returns {Promise<Error|Object>}
     */
    changePassword(model) {
        return this.patch('password', { body: model });
    }

    /**
     * Change profile
     * @param model
     * @returns {Promise<Error|Object>}
     */
    changeProfile(model) {
        return this.put('me', { body: model });
    }

    /**
     * Create Tab
     * @param model
     * @returns {Promise<Error|Object>}
     */
    createTab(model) {
        return this.post('tabs', { body: model });
    }

    /**
     * Create Promo Tab
     * @param model
     * @returns {Promise<Error|Object>}
     */
    createPromoTab(model) {
        return this.post('promotions', { body: model });
    }

    /**
     * Create promo code
     * @param model
     * @returns {Promise<Error|Object>}
     */
    createPromoCode(model) {
        return this.post('promocodes', { body: model });
    }

    /**
     * Get Tab list
     * @param pagination
     * @param filter
     * @param orderBy
     * @param orderType
     * @returns {Promise<Error|Object>}
     */
    getTabList(pagination, filter = undefined, orderBy = undefined, orderType = undefined) {
        const order = orderBy || orderType
            ? { orderBy, orderType }
            : undefined;

        const params = Object.assign({},
            pagination,
            order && order,
            filter && filter.length && { statuses: [...filter] }
        );
        return this.get('tabs', { query: params });
    }

    /**
     * Get Tab list
     * @param pagination
     * @param filter
     * @param orderBy
     * @param orderType
     * @param searchQuery
     * @returns {Promise<Error|Object>}
     */
    getPromoTabList(pagination, filter = undefined, orderBy = undefined, orderType = undefined, searchQuery = undefined) {
        const order = orderBy || orderType
            ? { orderBy, orderType }
            : undefined;

        const params = Object.assign({},
            pagination,
            order && order,
            filter && filter.length && { statuses: [...filter] },
            searchQuery !== undefined && { q: searchQuery }
        );

        return this.get('promotions', { query: params });
    }


    /**
     * Get promo codes list
     * @param pagination
     * @returns {Promise<Error|Object>}
     */
    getPromoCodesList(pagination) {
        const params = Object.assign({},
            pagination
        );

        return this.get('promocodes', { query: params });
    }

    /**
     * Add file to tab
     * @param id
     * @param model
     * @returns {Promise<Error|Object>}
     */
    addFileToTab(id, model) {
        return this.post(`tabs/${id}/files`, { body: model });
    }

    addFileToPromoTab(id, model) {
        return this.post(`promotions/${id}/files`, { body: model });
    }

    /**
     * Edit tab
     * @param id
     * @param model
     * @returns {Promise<Error|Object>}
     */
    editTab(id, model) {
        return this.put(`tabs/${id}`, { body: model });
    }

    /**
     * Edit tab
     * @param id
     * @param model
     * @returns {Promise<Error|Object>}
     */
    editPromoTab(id, model) {
        return this.put(`promotions/${id}`, { body: model });
    }

    /**
     * Edit promo code
     * @param id
     * @param model
     * @returns {Promise<Error|Object>}
     */
    editPromoCode(id, model) {
        return this.put(`promocodes/${id}`, { body: model });
    }

    /**
     * Get Admin list
     * @param pagination
     * @param orderBy
     * @param orderType
     * @returns {Promise<Error|Object>}
     */
    getAdminList(pagination, orderBy = undefined, orderType = undefined) {
        const order = orderBy || orderType
            ? { orderBy, orderType }
            : undefined;

        let params = Object.assign({},
            pagination,
            order && order
        );

        return this.get('', { query: params });
    }

    /**
     * Create Admin
     * @param model
     * @returns {Promise<Error|Object>}
     */
    createAdmin(model) {
        return this.post('', { body: model });
    }

    /**
     * Delete Admin
     * @param id
     * @returns {Promise<Error|Object>}
     */
    deleteAdmin(id) {
        return this.delete(`${id}`, {});
    }

    /**
     * Delete Promo code
     * @param id
     * @returns {Promise<Error|Object>}
     */
    deletePromoCode(id) {
        return this.delete(`promocodes/${id}`, {});
    }

    /**
     * Get User list
     * @param pagination
     * @param filter
     * @param orderBy
     * @param orderType
     * @param subscriptionPlanValue
     * @param periodValue
     * @returns {Promise<Error|Object>}
     */
    getUserList(
        pagination,
        filter = undefined,
        orderType = undefined,
        orderBy = undefined,
        subscriptionPlanValue = undefined,
        periodValue = undefined
    ) {
        const order = orderBy || orderType
            ? { orderBy, orderType }
            : undefined;
        let params = Object.assign({},
            pagination,
            order,
            filter && filter.length && { statuses: [...filter] },
            subscriptionPlanValue && { subscriptionPlan: subscriptionPlanValue },
            periodValue && { period: periodValue }
        );

        return this.get('users', { query: params });
    }

    /**
     * Get Fiidback list
     * @param pagination
     * @returns {Promise<Error|Object>}
     */
    getFeedbackList(pagination) {
        let params = Object.assign({},
            pagination
        );

        return this.get('feedbacks', { query: params });
    }

    /**
     * Send notification to users
     * @param model
     * @returns {Promise<Error|Object>}
     */
    sendPushNotification(model) {
        return this.post('notifications', { body: model });
    }

    /**
     * Send reply
     * @param id
     * @param model
     * @returns {Promise<Error|Object>}
     */
    sendReply(id, model) {
        return this.post(`feedbacks/${id}`, { body: model });
    }

    /**
     * Change app settings
     * @param model
     * @returns {Promise<Error|Object>}
     */
    changeSettings(model) {
        return this.patch('app-settings', { body: model });
    }

    /**
     * Invite users
     * @param model
     * @returns {Promise<Error|Object>}
     */
    inviteUsers(model) {
        return this.post('users', { body: model });
    }

    getFirstTabReleaseDate() {
        return this.get('tabs/release-date');
    }

    /**
     * Change promo tab status
     * @param id
     * @param model
     * @returns {Promise<Error|Object>}
     */
    changePromoTabStatus(id, model) {
        return this.patch(`promotions/${id}/status`, { body: model });
    }

    /**
     * Change tab status
     * @param id
     * @param model
     * @returns {Promise<Error|Object>}
     */
    changeTabStatus(id, model) {
        return this.patch(`tabs/${id}/status`, { body: model });
    }

    /**
     * Change tab status
     * @param id
     * @returns {Promise<Error|Object>}
     */
    releaseTabManually(id) {
        return this.patch(`tabs/${id}/release-manually`);
    }

    getActiveUserList() {
        return this.get(`users/active`);
    }

    getUserActivityList(pagination) {
        let params = Object.assign({},
            pagination,
        )
        return this.get(`statistics/user-activity`, {query: params});
    }
}

export default new Index();
