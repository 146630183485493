import notifications from '@iso/components/Feedback/Notification';
import {KEY_SESSION} from '@iso/lib/constants/storageKeys';
import {DELETE, GET, PATCH, POST, PUT, HttpStatusCode, contentTypes} from "@iso/lib/http";
import {redirectToLogin, redirectToServerErrorPage} from '@iso/lib/redirects';
import Auth from './auth';

/**
 * Base class for networking
 */
export default class NetworkBase {
    constructor(baseEndpoint) {
        this.base = baseEndpoint;
    }

    /**
     * Generic method for sending requests
     * @param payload
     * @returns {Promise<Error|Object>}
     */
    async executeRequest(payload) {
        const {endpoint, method, body, query, filename} = payload;
        const baseEndpoint = this.base ? `${this.base}/` : '';
        const requestOptions = {
            method: method,
            headers: {'Content-Type': 'application/json'},
        };
        let urlParams = '';
        let data;

        const session = JSON.parse(localStorage.getItem('SESSION'));

        if (session) {
            requestOptions.headers.authorization = `Bearer ${session.accessToken}`;
        }

        if (body) {
            requestOptions.body = JSON.stringify(body);
        }

        if (query) {
            let queryParams = [];

            // Prepares an query array to parse it into a URL object. Duplicates elements if the value is an array.
            Object.entries(query).forEach(([key, value]) => {
                if (Array.isArray(value)) {
                    // Transform 'key' value to 'key[]', to allow sending a single item array in the parameters.
                    return value.map(item => queryParams.push([`${key}[]`, item]));
                }
                queryParams.push([key, value]);
            });

            urlParams = new URLSearchParams([...queryParams]);
            urlParams = `?${urlParams}`;
        }

        try {
            data = await fetch(`${process.env.REACT_APP_SERVER_BASE}/${process.env.REACT_APP_SERVER_API_VERSION}/${baseEndpoint}${endpoint}${urlParams}`
                , requestOptions);
        } catch (error) {
            throw error;
        }

        if (data.status === HttpStatusCode.Unauthorized) {
            if (!session) {
                return redirectToLogin();
            }
            try {
                let response;
                response = await Auth.sendRefreshToken({refreshToken: session.refreshToken});
                response = await response.json();

                if (response.data) {
                    await localStorage.setItem(KEY_SESSION, JSON.stringify(response.data));
                    return this.executeRequest(payload);
                }
            } catch (error) {
                return redirectToLogin();
            }
        }

        if (data.status === HttpStatusCode.InternalError) {
            return redirectToServerErrorPage();
        }

        if (data.status === HttpStatusCode.NoContent) {
            return {};
        }

        const contentType = data.headers.get("content-type");

        if (contentType === contentTypes.FILE_CSV) {
            return this.parseStreamResponse(data, filename);
        }

        if (contentType === contentTypes.JSON) {
            return this.parseJSONResponse(data);
        }
    }

    /**
     * REST GET method
     * @param payload
     * @param endpoint
     * @returns {Promise<Error|Object>}
     */
    get(endpoint, payload) {
        return this.executeRequest({endpoint, method: GET, ...payload});
    }

    /**
     * REST POST method
     * @param payload
     * @param endpoint
     * @returns {Promise<Error|Object>}
     */
    post(endpoint, payload) {
        return this.executeRequest({endpoint, method: POST, ...payload});
    }

    /**
     * REST PUT method
     * @param payload
     * @param endpoint
     * @returns {Promise<Error|Object>}
     */
    put(endpoint, payload) {
        return this.executeRequest({endpoint, method: PUT, ...payload});
    }

    /**
     * REST PATCH method
     * @param payload
     * @param endpoint
     * @returns {Promise<Error|Object>}
     */
    patch(endpoint, payload) {
        return this.executeRequest({endpoint, method: PATCH, ...payload});
    }

    /**
     * REST DELETE method
     * @param payload
     * @param endpoint
     * @returns {Promise<Error|Object>}
     */
    delete(endpoint, payload) {
        return this.executeRequest({endpoint, method: DELETE, ...payload});
    }

    async parseJSONResponse(data) {
        let response;
        try {
            response = await data.json();
        } catch (error) {
            this.showError(data);
            throw error;
        }

        if (!data.ok) {
            this.showError(response, data.status);
            throw response.error;
        }

        if (data.ok && response) {
            return response;
        }
    }

    async parseStreamResponse(data, filename) {
        try {
            const blob = await data.blob();
            let saveData = (function () {
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                return function (data, fileName) {
                    let url = window.URL.createObjectURL(data);
                    a.href = url;
                    a.download = fileName;
                    a.click();
                    window.URL.revokeObjectURL(url);
                };
            }());
            return saveData(blob, filename);
        } catch (error) {
            this.showError(data);
            throw error;
        }
    }

    /**
     * Show error notification
     * @param data
     * @param status
     * @returns {*}
     */
    showError(data, status) {
        if (status === HttpStatusCode.Unprocessable && data.error.message === 'Token has been expired') {
            return redirectToLogin();
        }
        return notifications['error']({
            message: data.error.message || `${data.status} ${data.statusText}`,
        });
    }
}
