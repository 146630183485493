export const userRoles = {
    SUPER_ADMIN: 'Super Admin',
    ADMIN: 'Admin',
    USER: 'User'
}

export const userTypes = {
    SUPER_ADMIN: 'SUPER_ADMIN',
    ADMIN: 'ADMIN',
    USER: 'USER'
};
