import {all, takeEvery, put, fork} from 'redux-saga/effects';
import {createBrowserHistory} from 'history';
import {getSession, clearSession, setSession} from '@iso/lib/helpers/utility';
import notifications from '../../components/Feedback/Notification';
import Auth from '@iso/network/auth'
import Admins from "@iso/network/admins";
import Verifications from '@iso/network/verifications';
import actions from './actions';
import {userRoles} from "@iso/lib/constants/userRoles";
import { Redirect } from 'react-router';

const history = createBrowserHistory();

export function* loginRequest() {
    yield takeEvery(actions.LOGIN_REQUEST, function* ({payload}) {
        try {
            let {data} = yield Auth.login(payload);
            const {user, session} = data;
            const profile = {
                userId: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                role: userRoles[user.role]
            }
            setSession(session, profile)
            yield put({
                type: actions.LOGIN_SUCCESS,
                session: session,
                profile
            });
        } catch (error) {
            yield put({type: actions.LOGIN_ERROR});
        }
    });
}

export function* logout() {
    yield takeEvery(actions.LOGOUT, function* () {
        try {
            const response = yield Auth.logout();
            if (response) {
                clearSession();
                yield put({type: actions.LOGOUT_SUCCESS})
                history.push('/');
            }
        } catch (error) {
            yield put({type: actions.LOGOUT_ERROR});
        }
    });
}

export function* checkAuthorization() {
    yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
        const {session, profile} = getSession();
        if (session && profile) {
            yield put({
                type: actions.LOGIN_SUCCESS,
                session,
                profile
            });
        }
    });
}

export function* forgotPassword() {
    yield takeEvery(actions.FORGOT_PASSWORD, function* ({payload}) {
        const {email} = payload;

        try {
            const data = yield Verifications.forgotPassword(email);
            if (data) {
                yield put(actions.forgotPasswordSuccess(data));
                yield put(actions.clearAuthorization());
            }
        } catch { }
    });
}

export function* resetPassword() {
    yield takeEvery(actions.RESET_PASSWORD, function* ({payload}) {
        const {token, password} = payload;

        const response = yield Verifications.resetPassword({token, password});
        if (response) {
            yield put({type: actions.RESET_PASSWORD_SUCCESS});
            yield put(actions.clearAuthorization());
        }
    });
}

export function* createPassword() {
    yield takeEvery(actions.CREATE_PASSWORD, function* ({payload}) {
        const {token, password} = payload;

        const response = yield Verifications.resetPassword({token, password});
        if (response) {
            yield put({type: actions.CREATE_PASSWORD_SUCCESS});
            yield put(actions.clearAuthorization());
        }
    });
}

export function* changePassword() {
    yield takeEvery(actions.CHANGE_PASSWORD, function* ({payload}) {
        const {password, newPassword} = payload;

        try {
            const response = yield Admins.changePassword({password, newPassword});
            notifications['success']({
                message: 'A password has been successfully changed', //TODO: add translations
            });
        } catch (err) { }
    });
}

export function* changeProfile() {
    yield takeEvery(actions.CHANGE_PROFILE, function* ({payload}) {
        const {firstName, lastName} = payload;

        const response = yield Admins.changeProfile({firstName, lastName});
        if (response) {
            const profile = {
                userId: response.data.id,
                firstName: response.data.firstName,
                lastName: response.data.lastName,
                role: userRoles[response.data.role]
            }
            yield put({
                type: actions.CHANGE_PROFILE_SUCCESS,
                profile,
            });
            const {session} = getSession();
            setSession(session, profile);
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(checkAuthorization),
        fork(loginRequest),
        fork(logout),
        fork(forgotPassword),
        fork(resetPassword),
        fork(changePassword),
        fork(createPassword),
        fork(changeProfile),
    ]);
}
