import actions from '../actions';

const initState = {};

export default function tabReducer(state = initState, action) {
    switch (action.type) {
        case actions.NEW_DRINK_PURCHASE: {
            return {...state, ...action.payload}
        }
        default:
            return state;
    }
}
