import NetworkBase from "../base";

class Index extends NetworkBase {
    constructor() {
        super('admins/sessions');
    }

    /**
     * Admin login
     * @param model
     * @returns {Promise<Error|Object>}
     */
    login(model) {
        return this.post('', {body: model});
    }

    /**
     * Admin login
     * @param model
     * @returns {Promise<Error|Object>}
     */
    logout(model) {
        return this.delete('', {body: model});
    }

    /**
     * Admin re-login
     * @param model
     * @returns {Promise<Error|Object>}
     */
    sendRefreshToken(model) {
        return this.put('', {body: model});
    }
}

export default new Index();
