import React, { lazy, Suspense } from 'react';
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE } from './route.constants';
import Loader from '@iso/components/utility/loader';

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));

const NoMatch = lazy(() => import('@iso/containers/PublicPages/404/404'));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('@iso/containers/PublicPages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_404,
    component: lazy(() => import('@iso/containers/PublicPages/404/404')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_500,
    component: lazy(() => import('@iso/containers/PublicPages/500/500')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import('@iso/containers/PublicPages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.FORGOT_PASSWORD,
    component: lazy(() =>
      import('@iso/containers/PublicPages/ForgotPassword/ForgotPassword')
    ),
  },
  {
    path: PUBLIC_ROUTE.CREATE_PASSWORD,
    component: lazy(() =>
        import('@iso/containers/PublicPages/CreatePassword/CreatePassword')
    ),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: lazy(() =>
      import('@iso/containers/PublicPages/ResetPassword/ResetPassword')
    ),
  },
  {
    path: PUBLIC_ROUTE.TERMS_AND_CONDITIONS,
    component: lazy(() =>
        import('@iso/containers/PublicPages/TermsAndConditions/TermsAndConditions')
    ),
  },
  {
    path: PUBLIC_ROUTE.PRIVACY_POLICY,
    component: lazy(() =>
        import('@iso/containers/PublicPages/PrivacyPolicy/PrivacyPolicy')
    ),
  }
];
function PrivateRoute({ children, ...rest }) {
  const isLoggedIn = useSelector(state => state.Auth.session);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
            <PrivateRoute path="/dashboard">
              <Dashboard />
            </PrivateRoute>
            <Route path="*" component={NoMatch}/>
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
