import actions from './actions';

const initState = { session: null, isLoading: false };

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_REQUEST:
      return {...state, isLoading: true};
    case actions.LOGIN_SUCCESS:
      return {session: action.session, profile: action.profile, isLoading: false};
    case actions.CHANGE_PROFILE_SUCCESS:
      return {...state, profile: action.profile};
    case actions.LOGIN_ERROR:
      return {...state, isLoading: false};
    case actions.LOGOUT_ERROR:
      return {...state, isLoading: false};
    case actions.FORGOT_PASSWORD_SUCCESS:
      return {...state, message: action.payload, isSend: true};
    case actions.RESET_PASSWORD_SUCCESS:
      return {...state, message: action.payload, isReset: true};
    case actions.CREATE_PASSWORD_SUCCESS:
      return {...state, message: action.payload, isCreated: true};
    case actions.CLEAR_AUTHORIZATION:
      return {...state};
    case actions.LOGOUT_SUCCESS:
      return initState;
    default:
      return state;
  }
}
