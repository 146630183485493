import NetworkBase from "../base";

class Index extends NetworkBase {
    constructor() {
        super('');
    }

    /**
     * Send link to change password
     * @param model
     * @returns {Promise<Error|Object>}
     */
    forgotPassword(model) {
        return this.post('admins/verifications/password', {body: model})
    }

    /**
     * Change user password
     * @param model
     * @returns {Promise<Error|Object>}
     */
    resetPassword(model) {
        return this.put('verifications/password', {body: model})
    }

    /**
     * Validate token before changing password
     * @param model
     * @returns {Promise<Error|Object>}
     */
    validateToken(model) {
        return this.put('verifications/token', {body: model})
    }
}

export default new Index();
