const actions = {
    NEW_DRINK_PURCHASE: 'newDrinkPurchase'
}

export const events = {
    SEND_DRINK_PURCHASE_EVENT: 'receive:sendDrinkPurchaseEvent'
}

export const eventToAction = {
    [events.SEND_DRINK_PURCHASE_EVENT]: actions.NEW_DRINK_PURCHASE
}

export default actions;
